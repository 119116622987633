/* KaushanScript */

@font-face {
  font-family: "KaushanScript";
  src: url("./kaushan-script/KaushanScript-Regular.ttf");
}

/* Raleway */

@font-face {
  font-family: "RalewayExtraLight";
  src: url("./raleway/Raleway-ExtraLight.ttf");
}

@font-face {
  font-family: "RalewayLight";
  src: url("./raleway/Raleway-Light.ttf");
}

@font-face {
  font-family: "RalewayThin";
  src: url("./raleway/Raleway-Thin.ttf");
}

@font-face {
  font-family: "RalewayRegular";
  src: url("./raleway/Raleway-Regular.ttf");
}

@font-face {
  font-family: "RalewayMedium";
  src: url("./raleway/Raleway-Medium.ttf");
}

@font-face {
  font-family: "RalewaySemiBold";
  src: url("./raleway/Raleway-SemiBold.ttf");
}

@font-face {
  font-family: "RalewayBold";
  src: url("./raleway/Raleway-Bold.ttf");
}

@font-face {
  font-family: "RalewayExtraBold";
  src: url("./raleway/Raleway-ExtraBold.ttf");
}
