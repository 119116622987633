.maintenance {
    width: 100%;
    height: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    text-align: center;
    width: 600px;
}

.image {
    margin-bottom: 20px;
}

.image img {
    width: 150px;
    border-radius: 50%;
}

.title {
    margin-bottom: 15px;
    font-size: 72px;
}

.title span {
    color: white;
    font-family: "RalewaySemiBold", serif;
}

.subtitle span {
    color: white;
    font-family: "RalewayRegular", serif;
    font-size: 18px;
}
